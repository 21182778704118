export const menuItems = [
 { labelId: "headerMenu.instruction", value: "instructions" },
 { labelId: "headerMenu.developer", value: "developer" },
//  { labelId: "headerMenu.donate", value: "donate" }, // todo
];

export const russianMenuItems = [
 { labelId: "headerMenu.instruction", value: "instructions" },
 { labelId: "headerMenu.developer", value: "developer" },
];
