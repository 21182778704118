const Delete = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18">
      <path
        d="M16 3H0V5H2V16C2.00061 16.5302 2.21152 17.0386 2.58646 17.4135C2.9614 17.7885 3.46975 17.9994 4 18H12C12.5302 17.9994 13.0386 17.7885 13.4135 17.4135C13.7885 17.0386 13.9994 16.5302 14 16V5H16V3ZM12.001 16H4V5H12L12.001 16Z"
        fill="rgba(215, 215, 215, 0.929)"
      />
      <path d="M12 0H4V2H12V0Z" fill="rgba(215, 215, 215, 0.929)" />
    </svg>
  );
};

export default Delete;
